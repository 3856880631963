import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import { loadProducts } from "../../../actions/shopActions";

import Spinner from "../../Common/Spinner/Spinner";
import Hero from "./Hero/Hero";
import Shop from "./Shop/Shop";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.props.loadProducts();
  }

  render() {
    const { loading, categories } = this.props.shop;
    return (
      <Fragment>
        <Helmet>
          <title>
            Artisanal Curiosities | Hand crafted sauces, seasonings, and more
          </title>
          <meta
            name="description"
            content="We are artisans and purveyors of hand crafted sauces, seasonings, and other curiosities"
          />
        </Helmet>

        <Hero />
        <main role="main" className="container">
          <br />
          <center>Contact us at artisanalcuriosities@gmail.com</center>
        </main>
      </Fragment>
    );
  }
}

Home.propTypes = {
  loadProducts: PropTypes.func.isRequired,
  shop: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  shop: state.shop,
});

export default connect(mapStateToProps, { loadProducts })(Home);

/*
{loading.categories || loading.products ? <Spinner /> : <Shop />}
*/
