import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import styles from "./Category.module.css";

import Product from "./Product/Product";

var Category = ({ category, shop }) => {
  var { products } = shop;

  const sortOrder = (a, b) => {
    if (a.sort_order > b.sort_order) return 1;
    if (b.sort_order > a.sort_order) return -1;

    return 0;
  };

  products = products.filter((p) => {
    //Only select items that are published, have inventory, and in the category
    return (
      p.published &&
      p.inventory > 0 &&
      p.categories.map((c) => c._id).includes(category._id)
    );
  });

  products.sort(sortOrder);

  return (
    <div className="mb-5 mb-md-4">
      <div className="mb-2">
        <Link
          className={`h4 ${styles.categoryLink}`}
          to={`/shop/c/${category.url_tag}`}
        >
          {category.title}
        </Link>
      </div>
      <div className="row">
        {products.slice(0, 4).map((p, i) => (
          <Product key={p._id} product={p} index={i} />
        ))}
      </div>
      <div className="text-center text-md-left">
        <Link className="btn btn-light" to={`/shop/c/${category.url_tag}`}>
          More {category.title} <i class="fas fa-arrow-right"></i>
        </Link>
      </div>
    </div>
  );
};

Category.propTypes = {
  category: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  shop: state.shop,
});

export default connect(mapStateToProps, {})(Category);
