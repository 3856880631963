import axios from "axios";
import isEmpty from "../utils/isEmpty";

import {
  ADD_TO_CART,
  CART_LOADED,
  CART_LOAD_ERROR,
  UPDATE_CART,
} from "./types";

// Load cart
export const loadCart = () => async (dispatch) => {
  try {
    const cart = await axios.get(
      `/api/v1/carts?session=${localStorage.session}&sort_by=desc:_id`
    );

    if (isEmpty(cart.data)) {
      //Create new cart [Post]
      const newCart = await axios.post(`/api/v1/carts`, {
        session: localStorage.session,
      });
      dispatch({
        type: CART_LOADED,
        payload: newCart.data,
      });
    } else {
      //Load the most recent cart data into the reducer
      dispatch({
        type: CART_LOADED,
        payload: cart.data[0],
      });
    }
  } catch (err) {
    dispatch({
      type: CART_LOAD_ERROR,
    });
  }
};

// Add to Cart
export const addToCart = (data) => async (dispatch) => {
  dispatch({
    type: ADD_TO_CART,
    payload: {
      _id: data._id,
      quantity: parseInt(data.quantity),
      price: data.price,
    },
  });
};

// Update Cart
export const updateCart = (data) => async (dispatch) => {
  dispatch({
    type: UPDATE_CART,
    payload: {
      _id: data._id,
      quantity: parseInt(data.quantity),
      price: data.price,
    },
  });
};

// New Cart
export const createCart = (data) => async (dispatch) => {
  try {
    //Create new cart [Post]
    const newCart = await axios.post(`/api/v1/carts`, {
      session: localStorage.session,
    });
    dispatch({
      type: CART_LOADED,
      payload: newCart.data,
    });
  } catch (err) {
    dispatch({
      type: CART_LOAD_ERROR,
    });
  }
};
