import React, { useEffect } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import { v4 as uuidv4 } from "uuid";
import ReduxToastr from "react-redux-toastr";
import GA from "./utils/GoogleAnalytics";

//Utils
import ScrollToTop from "./utils/ScrollToTop";
import { setHTTPHeaders } from "./utils/setHTTPHeaders";

//Actions
import { loadUser } from "./actions/authActions";
import { loadCart } from "./actions/cartActions";
import { loadCategories as loadProductCategories } from "./actions/shopActions";
import { loadCategories as loadRecipeCategories } from "./actions/recipeActions";

//Components
import Routes from "./routing/Routes";

import "@fortawesome/fontawesome-free/css/all.css";

import "./Custom.scss";

if (!localStorage.session) {
  localStorage.setItem("session", uuidv4());
}

setHTTPHeaders();

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
    store.dispatch(loadCart());
    store.dispatch(loadProductCategories());
    store.dispatch(loadRecipeCategories());
  }, []);

  return (
    <Provider store={store}>
      <Router>
        {GA.init() && <GA.RouteTracker />}
        <ScrollToTop>
          <Route component={Routes} />
        </ScrollToTop>
      </Router>
      <ReduxToastr
        timeOut={4000}
        newestOnTop={false}
        preventDuplicates
        position="top-right"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
      />
    </Provider>
  );
};

export default App;
