import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";

import { isEmpty } from "../../../utils/validations";

import { logout } from "../../../actions/authActions";

var Login = ({ logout, location }) => {
  logout();
  return (
    <Redirect
      to={{
        pathname: "/login",
        state: {
          alert:
            location && location.state && !isEmpty(location.state.alert)
              ? location.state.alert
              : null,
          redir:
            location && location.state && !isEmpty(location.state.redir)
              ? location.state.redir
              : "/",
        },
      }}
    />
  );
};

Login.propTypes = {
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { logout })(Login);
