import React, { Fragment } from "react";
import Nav from "./Nav";
import Footer from "./Footer/Footer";

//Standard Template with Header/Footer

export default ({ children }) => {
  return (
    <Fragment>
      <Nav />
      {children}
      <Footer />
    </Fragment>
  );
};
