import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Category from "./Category";

class Shop extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { categories } = this.props.shop;

    return (
      <div>
        <div className="h2 mt-4 mb-3">Shop</div>
        {categories.map((c) => (
          <Category key={c._id} category={c} />
        ))}
      </div>
    );
  }
}

Shop.propTypes = {
  shop: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  shop: state.shop,
});

export default connect(mapStateToProps, {})(Shop);
