import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import jwt from "jsonwebtoken";

import { logout } from "../actions/authActions";

import Spinner from "../components/Common/Spinner/Spinner";

const PrivateRoute = (props) => {
  const {
    component: Component,
    auth: { token, isAuthenticated, user, loading },
    ...rest
  } = props;

  if (loading) {
    return <Spinner />;
  } else {
    const decoded = jwt.decode(token);
    const now = Math.round(new Date().getTime() / 1000);

    const isExpired = decoded && now >= decoded.exp;
    if (isExpired) {
      props.logout();
    }

    /* is token expired??? */
    if (!isAuthenticated || isExpired) {
      return (
        <Redirect
          to={{
            pathname: "/login",
            state: {
              alert: "login",
              redir: window.location.pathname + window.location.search,
            },
          }}
        />
      );
    }

    if (!user.roles.includes("Admin")) {
      return (
        <Redirect
          to={{
            pathname: "/page/403",
            state: {
              redir: window.location.pathname + window.location.search,
            },
          }}
        />
      );
    }

    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(PrivateRoute);
