import React, { Fragment } from "react";
import { connect } from "react-redux";
//import PropTypes from "prop-types";

import styles from "./Hero.module.css";

var Hero = ({}) => {
  return (
    <div className={`${styles.heroContainer}`}>
      <div className="row">
        <div className="col-12 col-md-5 text-center text-md-right pr-md-5">
          <img
            src="https://res.cloudinary.com/hxcovqiub/image/upload/v1613150741/Logos/artisanal-curiosities-logo_medium-white_jhw1jg.png"
            style={{ width: "300px", height: "118px" }}
          />
        </div>
        <div className="col-12 col-md-7">
          <div className="text-center mt-5 mt-md-3" style={{ float: "left" }}>
            <span
              className="mb-3 mb-md-1"
              style={{
                display: "inline-block",
                fontFamily: '"Prompt", sans-serif',
                fontWeight: "600",
                letterSpacing: ".2rem",
                fontSize: "2.5rem",
                textTransform: "uppercase",
                lineHeight: "2.5rem",
              }}
            >
              Tantalizing Delights
            </span>
            <br />
            <span
              className="mb-3 mb-md-1"
              style={{
                display: "inline-block",
                fontSize: "1.5rem",
                letterSpacing: ".1rem",
                lineHeight: "1.5rem",
              }}
            >
              Sauces, Seasonings <br className="d-md-none" />& more
            </span>
            <br />
            <span
              style={{
                display: "inline-block",
                fontFamily: '"Prompt", sans-serif',
                fontSize: ".75rem",
                textTransform: "uppercase",
                letterSpacing: ".05rem",
              }}
            >
              Hand Crafted &#8226; Locally Made
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

//Hero.propTypes = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(Hero);
