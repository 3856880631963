import {
  CATEGORIES_LOADED,
  CATEGORIES_LOAD_ERROR,
  CATEGORY_LOADED,
  CATEGORY_LOAD_ERROR,
  CATEGORY_UNLOAD,
  PRODUCTS_LOADED,
  PRODUCTS_LOAD_ERROR,
  PRODUCT_LOADED,
  PRODUCT_LOAD_ERROR,
  PRODUCT_UNLOAD,
} from "../actions/types";

const initialState = {
  loading: {
    categories: true,
    category: true,
    products: true,
    product: true,
  },
  categories: [],
  category: null,
  products: [],
  product: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CATEGORIES_LOADED:
      return {
        ...state,
        categories: payload,
        loading: {
          ...state.loading,
          categories: false,
        },
      };
    case CATEGORIES_LOAD_ERROR:
      return {
        ...state,
        categories: [],
        loading: {
          ...state.loading,
          categories: false,
        },
      };
    case CATEGORY_LOADED:
      return {
        ...state,
        category: payload,
        loading: {
          ...state.loading,
          category: false,
        },
      };

    case CATEGORY_LOAD_ERROR:
      return {
        ...state,
        category: null,
        loading: {
          ...state.loading,
          category: false,
        },
      };
    case CATEGORY_UNLOAD:
      return {
        ...state,
        category: null,
        loading: {
          ...state.loading,
          category: true,
        },
      };
    case PRODUCTS_LOADED:
      return {
        ...state,
        products: payload,
        loading: {
          ...state.loading,
          products: false,
        },
      };
    case PRODUCTS_LOAD_ERROR:
      return {
        ...state,
        products: [],
        loading: {
          ...state.loading,
          products: false,
        },
      };
    case PRODUCT_LOADED:
      return {
        ...state,
        product: payload,
        loading: {
          ...state.loading,
          product: false,
        },
      };

    case PRODUCT_LOAD_ERROR:
      return {
        ...state,
        product: null,
        loading: {
          ...state.loading,
          product: false,
        },
      };
    case PRODUCT_UNLOAD:
      return {
        ...state,
        product: null,
        loading: {
          ...state.loading,
          product: true,
        },
      };
    default:
      return state;
  }
}
