import React, { Component } from "react";
import { connect } from "react-redux";
//import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import parse from "html-react-parser";
import validator from "validator";
import axios from "axios";
import Recaptcha from "react-google-invisible-recaptcha";

import { propErrs } from "../../../../../../utils/errManager";
//import { register } from "../../../../../../actions/authActions";

import Text from "../../../../Forms/Text";
import FormErrs from "../../../../Forms/FormErrs";

class MailingListForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formErrs: null,
      recaptchaProcessing: false,
      recaptchaErr: null,
      success: false,
    };
  }

  onSubmit = (e) => {
    this.setState({ recaptchaProcessing: true });
    this.recaptcha.execute();
  };

  validate = (values) => {
    const errors = [];
    //Validate Email Address
    if (!values.email) {
      errors.push("Enter your email address");
    } else if (!validator.isEmail(values.email)) {
      errors.push("Invalid email address");
    }
    return errors;
  };

  onResolved = async (formData) => {
    //Reset any previous errors
    this.setState({ recaptchaErr: null });

    var gre = this.recaptcha.getResponse();

    //Validate recaptcha
    if (!gre) {
      this.setState({
        recaptchaErr: "Please validate that you are not a robot",
      });
    }

    //Validate form data
    /*const errors = this.validate(formData);
    if (!isEmpty(errors)) {
      this.recaptcha.reset();
      this.setState({ recaptchaProcessing: false });
      this.setState({ formErrs: errors }); //Display Global errors
      return;
    }*/

    try {
      //Submit mailing list add
      var res = await axios.post(`/api/v1/mailing-list`, {
        email: formData.email,
        recaptcha_token: gre,
      });

      this.setState({ success: true, recaptchaProcessing: false });

      console.log(res);
    } catch (err) {
      this.recaptcha.reset();
      this.setState({ recaptchaProcessing: false });
      //Manage Errors
      let errors = await propErrs(err); // Propogate Error Formatting
      let fields = Object.values(errors.fields);
      this.setState({ formErrs: [...errors.alerts, ...fields] }); //Display Global errors
    }
  };

  render() {
    const { recaptchaProcessing, recaptchaErr, formErrs, success } = this.state;
    const { submitting, handleSubmit } = this.props;
    return success ? (
      <div className="alert alert-success" role="alert">
        <div className="d-flex align-items-center">
          <i className="fas fa-check-circle fa-2x mr-3"></i>
          <span>Thank you! We look forward to staying in touch.</span>
        </div>
      </div>
    ) : (
      <form id="mailing-list-form" onSubmit={handleSubmit(this.onSubmit)}>
        <section>
          <div class="input-label-group mb-3">
            <Field
              name="email"
              type="email"
              placeholder=" "
              id="mailingListEmail"
              maxLength="250"
              component={Text}
            />
            <label htmlFor="registerEmail">Email Address</label>

            <div class="input-group-append">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={submitting || recaptchaProcessing}
              >
                {submitting || recaptchaProcessing
                  ? parse('<i class="fas fa-spinner fa-spin"></i>')
                  : "Submit"}
              </button>
            </div>
          </div>
        </section>

        <section>
          <Recaptcha
            ref={(ref) => (this.recaptcha = ref)}
            sitekey={process.env.REACT_APP_GRE}
            onResolved={handleSubmit(this.onResolved)}
          />
          {recaptchaErr && (
            <div className="field-error">
              <i class="fas fa-exclamation-circle" /> {recaptchaErr}
            </div>
          )}
        </section>
        <section>
          <FormErrs errors={formErrs} />
        </section>
      </form>
    );
  }
}

MailingListForm = reduxForm({
  form: "mailingListForm",
})(MailingListForm);

MailingListForm.propTypes = {
  //register: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(MailingListForm);
