import React, { Fragment } from "react";
import { StatusCode } from "react-http-status-code";
import { Helmet } from "react-helmet";

let Page404 = (props) => {
  return (
    <Fragment>
      <StatusCode code={404}>
        <Helmet>
          <title>Page Not Found | Artisanal Curiosities</title>
        </Helmet>
        <main role="main" className="container nav-spacer">
          <h1>Oops...</h1>
          <p>The page you are looking for could not be found.</p>
        </main>
      </StatusCode>
    </Fragment>
  );
};

export default Page404;
