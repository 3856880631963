import React from "react";
import isEmpty from "../../../utils/isEmpty";
import classnames from "classnames";

export const FormErrs = ({ errors }) => {
  return (
    <div
      id="form-errors"
      className={classnames("alert alert-danger", {
        "form-errors__show": !isEmpty(errors),
      })}
      role="alert"
    >
      {!isEmpty(errors) && errors.map((e, i) => <li key={i}>{e}</li>)}
    </div>
  );
};
export default FormErrs;
