import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import Category from "./Category";
import MailingListForm from "./MailingListForm";
import Spinner from "../../../../Spinner/Spinner";

const Footer = ({ shop }) => {
  var year = new Date().getFullYear();

  const { categories } = shop;

  return (
    <footer>
      <div className="container">
        <div className="container">
          <div className="copy">
            <div className="row">
              <div className="col text-center">
                Copyright &#169; {year} Artisanal Curiosities. All Rights
                Reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  shop: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  shop: state.shop,
});

export default connect(mapStateToProps, {})(Footer);

/*
<div className="widgets">
          <div className="row">
            <div className="col-6 col-md-4">
              <h3 className="menu-header">Shop</h3>
              <ul className="menu-list">
                {categories.loading ? (
                  <Spinner />
                ) : (
                  categories.map((c) => <Category key={c._id} category={c} />)
                )}
              </ul>
            </div>
            <div className="col-6 col-md-4">
              <h3 className="menu-header">Connect</h3>
              <ul className="menu-list">
                <li>
                  <Link to="/contact-us">
                    <i className="fas fa-envelope fa-fw"></i> Contact us
                  </Link>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/artisanalcuriosities"
                    target="_facebook"
                  >
                    <i className="fab fa-facebook fa-fw"></i> Facebook
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/artisanalcuriosities/"
                    target="_instagram"
                  >
                    <i className="fab fa-instagram fa-fw"></i> Instagram
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-12 col-md-4 mt-4 mt-md-0">
              <h3 className="menu-header text-center text-md-left">
                Join Our Mailing List
              </h3>
              <div className="row justify-content-center justify-content-md-start no-gutters ">
                <div className="col-12">
                  <MailingListForm />
                </div>
              </div>
            </div>
          </div>
        </div>
        */
