import axios from "axios";
import {
  CATEGORIES_LOADED,
  CATEGORIES_LOAD_ERROR,
  CATEGORY_LOADED,
  CATEGORY_LOAD_ERROR,
  CATEGORY_UNLOAD,
  PRODUCTS_LOADED,
  PRODUCTS_LOAD_ERROR,
  PRODUCT_LOADED,
  PRODUCT_LOAD_ERROR,
  PRODUCT_UNLOAD,
} from "./types";

import { isEmpty } from "../utils/validations";

// Load all Categories
export const loadCategories = () => async (dispatch) => {
  try {
    const res = await axios.get(`/api/v1/categories?sort_by=asc:sort_order`);

    dispatch({
      type: CATEGORIES_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: CATEGORIES_LOAD_ERROR,
    });
  }
};

//Load a category by url tag
export const loadCategoryByTag = (url_tag) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/v1/categories?url_tag=${url_tag}`);

    if (res.data.length !== 1) {
      throw new Error("Error getting category");
    }

    dispatch({
      type: CATEGORY_LOADED,
      payload: res.data[0],
    });
  } catch (err) {
    dispatch({
      type: CATEGORY_LOAD_ERROR,
    });
  }
};

//Unload Category
export const unloadCategory = () => async (dispatch) => {
  dispatch({
    type: CATEGORY_UNLOAD,
  });
};

// Load all Products
export const loadProducts = () => async (dispatch) => {
  try {
    const res = await axios.get(`/api/v1/products?sort_by=asc:sort_order`);

    dispatch({
      type: PRODUCTS_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PRODUCTS_LOAD_ERROR,
    });
  }
};

//Load a product by url tag
export const loadProductByTag = (url_tag) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/v1/products?url_tag=${url_tag}`);

    if (res.data.length !== 1) {
      throw new Error("Error getting product");
    }

    dispatch({
      type: PRODUCT_LOADED,
      payload: res.data[0],
    });
  } catch (err) {
    dispatch({
      type: PRODUCT_LOAD_ERROR,
    });
  }
};

//Load a product by ID
export const loadProductByID = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/v1/products/${id}`);

    if (isEmpty(res.data)) {
      throw new Error("Error getting product");
    }

    dispatch({
      type: PRODUCT_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PRODUCT_LOAD_ERROR,
    });
  }
};

//Unload Product
export const unloadProduct = () => async (dispatch) => {
  dispatch({
    type: PRODUCT_UNLOAD,
  });
};
