import {
  ADD_TO_CART,
  CART_LOADED,
  CART_LOAD_ERROR,
  UPDATE_CART,
} from "../actions/types";

const initialState = {
  _id: null,
  products: [],
  loading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_TO_CART:
      var index = state.products.findIndex((p) => p._id === payload._id);

      if (index === -1) {
        return {
          ...state,
          products: [...state.products, payload],
          loading: false,
        };
      } else {
        var products = state.products;
        products[index].quantity =
          parseInt(products[index].quantity) + payload.quantity;
        products[index].price = payload.price;
        return {
          ...state,
          products: [...products],
          loading: false,
        };
      }
    case CART_LOADED:
      return {
        ...state,
        _id: payload._id,
        products: payload.products,
        loading: false,
      };
    case CART_LOAD_ERROR:
      return {
        ...state,
        _id: null,
        products: [],
        loading: false,
      };
    case UPDATE_CART:
      var index = state.products.findIndex((p) => p._id === payload._id);
      if (index === -1) {
        return {
          ...state,
          products: [...state.products, payload],
          loading: false,
        };
      } else if (index !== -1 && payload.quantity === 0) {
        return {
          ...state,
          products: state.products.filter((p) => p._id !== payload._id),
          loading: false,
        };
      } else {
        var products = state.products;
        products[index].quantity = parseInt(payload.quantity);
        products[index].price = payload.price;
        return {
          ...state,
          products: [...products],
          loading: false,
        };
      }

    default:
      return state;
  }
}
