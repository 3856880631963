import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { formatRating } from "../../../../../utils/formatting";

import FiveStarObject from "../../../../Common/FiveStarObject";

var RatingsSummary = ({ product }) => {
  var reviews = product.reviews && product.reviews.filter((r) => r.approved);
  var rating = formatRating({ reviews });

  return (
    rating.count > 0 && (
      <Fragment>
        <FiveStarObject count={rating.rating} /> <small>({rating.count})</small>
      </Fragment>
    )
  );
};

RatingsSummary.propTypes = {
  product: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(RatingsSummary);
