import React from "react";

export default ({ count }) => {
  console.log(count);
  if (count === 0) {
    return (
      <span>
        <i className="fas fa-pepper-hot text-light"></i>
        <i className="fas fa-pepper-hot text-light"></i>
        <i className="fas fa-pepper-hot text-light"></i>
        <i className="fas fa-pepper-hot text-light"></i>
        <i className="fas fa-pepper-hot text-light"></i>
      </span>
    );
  } else if (count <= 1) {
    return (
      <span>
        <i className="fas fa-pepper-hot text-danger"></i>
        <i className="fas fa-pepper-hot text-light"></i>
        <i className="fas fa-pepper-hot text-light"></i>
        <i className="fas fa-pepper-hot text-light"></i>
        <i className="fas fa-pepper-hot text-light"></i>
      </span>
    );
  } else if (count <= 2) {
    return (
      <span>
        <i className="fas fa-pepper-hot text-danger"></i>
        <i className="fas fa-pepper-hot text-danger"></i>
        <i className="fas fa-pepper-hot text-light"></i>
        <i className="fas fa-pepper-hot text-light"></i>
        <i className="fas fa-pepper-hot text-light"></i>
      </span>
    );
  } else if (count <= 3) {
    return (
      <span>
        <i className="fas fa-pepper-hot text-danger"></i>
        <i className="fas fa-pepper-hot text-danger"></i>
        <i className="fas fa-pepper-hot text-danger"></i>
        <i className="fas fa-pepper-hot text-light"></i>
        <i className="fas fa-pepper-hot text-light"></i>
      </span>
    );
  } else if (count <= 4) {
    return (
      <span>
        <i className="fas fa-pepper-hot text-danger"></i>
        <i className="fas fa-pepper-hot text-danger"></i>
        <i className="fas fa-pepper-hot text-danger"></i>
        <i className="fas fa-pepper-hot text-danger"></i>
        <i className="fas fa-pepper-hot text-light"></i>
      </span>
    );
  } else {
    return (
      <span>
        <i className="fas fa-pepper-hot text-danger"></i>
        <i className="fas fa-pepper-hot text-danger"></i>
        <i className="fas fa-pepper-hot text-danger"></i>
        <i className="fas fa-pepper-hot text-danger"></i>
        <i className="fas fa-pepper-hot text-danger"></i>
      </span>
    );
  }
};
