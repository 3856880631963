import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classNames from "classnames";

import styles from "./Product.module.css";

import Image from "./Image";
import Price from "./Price";
import RatingsSummary from "./RatingsSummary";
import FivePepperObject from "../../../../Common/FivePepperObject";

var Product = ({ product, index }) => {
  return (
    <div
      className={classNames(
        "col-12 col-md-3 px-md-2 mb-5 mb-md-3 text-center d-md-inline-block",
        {
          "d-none": index > 2,
        }
      )}
    >
      <Image product={product} />
      <div className={`${styles.title} mt-2`}>
        <Link to={`/shop/p/${product.url_tag}`}>{product.titles.short}</Link>
      </div>

      <div>
        <FivePepperObject count={product.heat_index} />
      </div>

      <Price product={product} />
      <RatingsSummary product={product} />
    </div>
  );
};

Product.propTypes = {
  product: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  //shop: state.shop,
});

export default connect(mapStateToProps, {})(Product);
