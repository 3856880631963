const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0);

const passwordFormat = (value = "") => {
  let errorCheck = [];
  //Test if contains lowercase
  if (!/[a-z]/.test(value)) {
    errorCheck.push("lower");
  }

  //Test is contains uppercase
  if (!/[A-Z]/.test(value)) {
    errorCheck.push("upper");
  }

  //Test if contains digits
  if (!/\d/.test(value)) {
    errorCheck.push("digits");
  }

  //Test if contains symbols
  if (!/[^\w\s]/.test(value)) {
    errorCheck.push("symbols");
  }

  if (isEmpty(errorCheck)) {
    return {
      valid: true,
      response: null,
    };
  } else {
    let error_list = {
      min: "minimum 8 characters",
      max: "maximum 100 characters",
      upper: "an uppercase letter",
      lower: "a lowercase letter",
      digits: "a number",
      symbols: "a symbol (eg !@#$)",
    };

    var response = "Password must include: ";
    var resCount = 1;
    var errCount = errorCheck.length;

    errorCheck.map(function (currentValue, index, arr) {
      response +=
        (resCount == 1 ? "" : errCount == resCount ? " and " : ", ") +
        error_list[currentValue];
      resCount++;
    });

    return {
      valid: false,
      response,
    };
  }
};

const userHasReviewed = ({ reviews, user }) => {
  if (isEmpty(reviews)) {
    return false;
  }
  if (isEmpty(user)) {
    return false;
  }
  if (!isEmpty(reviews.find((r) => r.user._id == user))) {
    return true;
  }
  return false;
};

const decimalCount = (num) => {
  // Convert to String
  const numStr = String(num);
  // String Contains Decimal
  if (numStr.includes(".")) {
    return numStr.split(".")[1].length;
  }
  // String Does Not Contain Decimal
  return 0;
};

export { passwordFormat, isEmpty, userHasReviewed, decimalCount };
