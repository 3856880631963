import axios from "axios";
import {
  RECIPES_CATEGORIES_LOADED,
  RECIPES_CATEGORIES_LOAD_ERROR,
  RECIPES_CATEGORY_LOADED,
  RECIPES_CATEGORY_LOAD_ERROR,
  RECIPES_CATEGORY_UNLOAD,
  RECIPES_LOADED,
  RECIPES_LOAD_ERROR,
  RECIPE_LOADED,
  RECIPE_LOAD_ERROR,
  RECIPE_UNLOAD,
} from "./types";

import { isEmpty } from "../utils/validations";

// Load all Categories
export const loadCategories = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `/api/v1/recipes/categories?sort_by=asc:sort_order`
    );

    dispatch({
      type: RECIPES_CATEGORIES_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: RECIPES_CATEGORIES_LOAD_ERROR,
    });
  }
};

//Load a category by url tag
export const loadCategoryByTag = (url_tag) => async (dispatch) => {
  try {
    const res = await axios.get(
      `/api/v1/recipes/categories?url_tag=${url_tag}`
    );

    if (res.data.length !== 1) {
      throw new Error("Error getting category");
    }

    dispatch({
      type: RECIPES_CATEGORY_LOADED,
      payload: res.data[0],
    });
  } catch (err) {
    dispatch({
      type: RECIPES_CATEGORY_LOAD_ERROR,
    });
  }
};

//Unload Category
export const unloadCategory = () => async (dispatch) => {
  dispatch({
    type: RECIPES_CATEGORY_UNLOAD,
  });
};

// Load all Recipes
export const loadRecipes = () => async (dispatch) => {
  try {
    const res = await axios.get(`/api/v1/recipes?sort_by=asc:sort_order`);

    dispatch({
      type: RECIPES_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: RECIPES_LOAD_ERROR,
    });
  }
};

//Load a recipe by url tag
export const loadRecipeByTag = (url_tag) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/v1/recipes?url_tag=${url_tag}`);

    if (res.data.length !== 1) {
      throw new Error("Error getting recipe");
    }

    dispatch({
      type: RECIPE_LOADED,
      payload: res.data[0],
    });
  } catch (err) {
    dispatch({
      type: RECIPE_LOAD_ERROR,
    });
  }
};

//Load a product by ID
export const loadRecipeByID = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/v1/recipes/${id}`);

    if (isEmpty(res.data)) {
      throw new Error("Error getting recipes");
    }

    dispatch({
      type: RECIPE_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: RECIPE_LOAD_ERROR,
    });
  }
};

//Unload Recipe
export const unloadRecipe = () => async (dispatch) => {
  dispatch({
    type: RECIPE_UNLOAD,
  });
};
