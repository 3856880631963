import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const Footer = ({ category }) => {
  return (
    <li>
      <Link to={`/shop/c/${category.url_tag}`}>{category.title}</Link>
    </li>
  );
};

//Footer.propTypes = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(Footer);
