import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import styles from "./Image.module.css";

class Image extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { product } = this.props;

    var image = product.images[0].url;
    var label = product.images[0].description;

    const inStock = product.inventory > 0;

    return (
      <Fragment>
        <div>
          <Link to={`/shop/p/${product.url_tag}`}>
            <div
              className={styles.square + (!inStock ? ` ${styles.dimmed}` : "")}
              style={{
                backgroundImage: `url(${image})`,
              }}
              aria-label={label}
            >
              {!inStock && <div className={`${styles.text}`}>Sold Out</div>}
            </div>
          </Link>
        </div>
      </Fragment>
    );
  }
}

Image.propTypes = {
  product: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  //shop: state.shop,
});

export default connect(mapStateToProps, {})(Image);
