import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { logout } from "../../../../../actions/authActions";

const Navigation = ({ auth, logout, cart }) => {
  var [cartCount, updateCartCount] = useState(0);

  useEffect(() => {
    window.addEventListener("click", closeMenus);
  }, []);

  useEffect(() => {
    var count = 0;
    if (cart && !cart.loading) {
      count = cart.products.reduce(
        (accumulator, p) => accumulator + p.quantity,
        count
      );
    }
    updateCartCount(count);
  }, [cart]);

  const toggleOpen = (e) => {
    var elements = document.getElementsByClassName("offcanvas-collapse");
    var i;
    for (i = 0; i < elements.length; i++) {
      let element = elements[i];
      if (element.classList) {
        element.classList.toggle("open");
      } else {
        //for IE9
        var classes = element.className.split(" ");
        var i = classes.indexOf("open");
        if (i > 0) {
          classes.splice(i, 1);
        } else {
          classes.push("open");
          element.className = classes.join(" ");
        }
      }
    }
  };

  const closeNavMenu = (e) => {
    var elements = document.getElementsByClassName("offcanvas-collapse");
    var i;
    for (i = 0; i < elements.length; i++) {
      let element = elements[i];
      element.classList.remove("open");
    }
  };

  const closeMenus = (e) => {
    var parent = e.target.closest(".dropdown");
    if (parent) return;

    var elements = document.getElementsByClassName("dropdown");
    var i;
    for (i = 0; i < elements.length; i++) {
      let element = elements[i];
      element.classList.remove("show");
    }

    var elements = document.getElementsByClassName("dropdown-menu");
    var i;
    for (i = 0; i < elements.length; i++) {
      let element = elements[i];
      element.classList.remove("show");
    }
  };

  const handleLogout = (e) => {
    e && e.preventDefault();
    toggleDropdown(e);
    logout();
  };

  const toggleClass = ({ element, toggleClass }) => {
    if (element.classList) {
      element.classList.toggle(toggleClass);
    } else {
      // For IE9
      var classes = element.className.split(" ");
      var i = classes.indexOf(toggleClass);

      if (i >= 0) classes.splice(i, 1);
      else classes.push(toggleClass);
      element.className = classes.join(" ");
    }
  };

  const toggleDropdown = (e) => {
    var parent = e.target.closest(".dropdown");
    var menu = parent.querySelector(".dropdown-menu");
    toggleClass({ element: parent, toggleClass: "show" });
    toggleClass({ element: menu, toggleClass: "show" });
  };

  return (
    <nav
      class="navbar navbar-expand-lg fixed-top px-md-5 py-3"
      aria-label="Main navigation"
    ></nav>
  );
};

Navigation.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  cart: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  cart: state.cart,
});

export default connect(mapStateToProps, { logout })(Navigation);

/*
<div class="container-fluid">
        <Link
          className="navbar-brand"
          to="/"
          onClick={(e) => closeNavMenu(e)}
        />

        <Link
          className="d-md-none ml-auto mr-2 nav-cart-icon"
          to="/shop/cart"
          onClick={(e) => closeNavMenu(e)}
        >
          <i className="fas fa-shopping-cart"></i>
          <span className="badge badge-pill badge-light">{cartCount}</span>
        </Link>

        <button
          class="navbar-toggler p-0 border-0"
          type="button"
          data-bs-toggle="offcanvas"
          aria-label="Toggle navigation"
          onClick={(e) => toggleOpen(e)}
        >
          <i className="fas fa-bars"></i>
        </button>

        <div
          class="navbar-collapse offcanvas-collapse"
          id="navbarsExampleDefault"
        >
          <ul class="navbar-nav me-auto mb-0">
            <li class="nav-item">
              <Link className="nav-link" to="/" onClick={(e) => toggleOpen(e)}>
                Home
              </Link>
            </li>
            <li class="nav-item">
              <Link
                className="nav-link"
                to="/shop/c/hot-sauces"
                onClick={(e) => toggleOpen(e)}
              >
                Hot Sauces
              </Link>
            </li>
            <li class="nav-item">
              <Link
                className="nav-link"
                to="/shop/c/dried-pepper-blends"
                onClick={(e) => toggleOpen(e)}
              >
                Dried Peppers
              </Link>
            </li>
            <li class="nav-item">
              <Link
                className="nav-link"
                to="/shop/c/seasonings"
                onClick={(e) => toggleOpen(e)}
              >
                Seasonings
              </Link>
            </li>
          </ul>
          <ul class="navbar-nav ml-auto">
            <li className="nav-item">
              <Link
                className="nav-link d-none d-md-block"
                to="/shop/cart"
                onClick={(e) => toggleOpen(e)}
              >
                <i className="fas fa-shopping-cart"></i>
                <span className="badge badge-pill badge-light">
                  {cartCount}
                </span>
              </Link>
            </li>
            {auth.isAuthenticated ? (
              <Fragment>
                <li className="nav-item d-md-none">
                  <Link
                    className="nav-link"
                    to="/account"
                    onClick={(e) => toggleOpen(e)}
                  >
                    <i className="fas fa-user fa-fw"></i> Your Account
                  </Link>
                </li>
                <li className="nav-item d-md-none">
                  <a
                    href="#"
                    className="nav-link"
                    onClick={(e) => {
                      handleLogout(e);
                      toggleOpen(e);
                    }}
                  >
                    <i className="fas fa-sign-out-alt fa-fw"></i> Log Out
                  </a>
                </li>
                <li class="d-none d-md-block nav-item dropdown">
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    id="nav-profile-dropdown"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    onClick={(e) => {
                      e && e.preventDefault();
                      toggleDropdown(e);
                    }}
                  >
                    <i className="fas fa-user"></i>
                  </a>
                  <div
                    class="dropdown-menu dropdown-menu-right"
                    aria-labelledby="nav-profile-dropdown"
                  >
                    <Link
                      className="dropdown-item"
                      to="/account"
                      onClick={(e) => {
                        toggleDropdown(e);
                        toggleOpen(e);
                      }}
                    >
                      <i className="fas fa-user fa-fw"></i> Your Account
                    </Link>
                    <a
                      href="#"
                      className="dropdown-item"
                      onClick={(e) => {
                        handleLogout(e);
                        toggleOpen(e);
                      }}
                    >
                      <i className="fas fa-sign-out-alt fa-fw"></i> Log Out
                    </a>
                  </div>
                </li>
              </Fragment>
            ) : (
              <Fragment>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/login"
                    onClick={(e) => toggleOpen(e)}
                  >
                    Login
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/register"
                    onClick={(e) => toggleOpen(e)}
                  >
                    Register
                  </Link>
                </li>
              </Fragment>
            )}
          </ul>
        </div>
      </div>
            */
