import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { reducer as toastrReducer } from "react-redux-toastr";

import auth from "./authReducers";
import shop from "./shopReducers";
import cart from "./cartReducers";
import recipes from "./recipeReducers";

export default combineReducers({
  auth,
  shop,
  cart,
  recipes,
  form: formReducer,
  toastr: toastrReducer,
});
