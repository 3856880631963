import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";

import styles from "./Product.module.css";

import { formatMoney } from "../../../../../utils/formatting";
import isEmpty from "../../../../../utils/isEmpty";

var Price = ({ product }) => {
  const isSale = !isEmpty(product.prices.sale) ? true : false;

  const price = isSale ? product.prices.sale : product.prices.regular;

  return (
    <div className={`${styles.price} text-center`}>
      {isSale && (
        <Fragment>
          <span class="badge badge-danger">Sale</span>{" "}
        </Fragment>
      )}
      <span
        className={classnames("text-strong", {
          "text-danger": isSale,
        })}
      >
        ${formatMoney(price)}
      </span>
      {isSale && (
        <span>
          {" "}
          <small className="text-strike text-muted">
            ${formatMoney(product.prices.regular)}
          </small>
        </span>
      )}
    </div>
  );
};

Price.propTypes = {
  product: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(Price);
