import { SubmissionError } from "redux-form";
import parse from "html-react-parser";
import { toastr } from "react-redux-toastr";
import dot from "dot-object";

import isEmpty from "./isEmpty";

export const propErrs = (err) => {
  /*
    var err_response = {
      error: {
        "message": "Validation errors in your request", //optional,
        errors: [
          {
            code: "test-error",
            msg: "This is a test error"
          },
          {
            param: "field_name",
            msg: "Request parameters did not validate",
            value: "param value" //optional
          }
        ]
      }
    };
  */

  const errors = {
    fields: {},
    alerts: [],
  };

  if (
    !err.response ||
    !err.response.data ||
    !err.response.data.error ||
    !err.response.data.error.errors
  ) {
    errors.alerts.push(
      "There was an unexpected error, please reload the page and try again"
    );
  } else {
    err.response.data.error.errors.map((error) => {
      if (error.param) {
        errors.fields[error.param] = error.msg;
      } else {
        errors.alerts.push(error.msg);
      }
    });
  }

  return errors;
};

export const setFormFieldErrs = (fieldErrs) => {
  //Field errors
  if (!isEmpty(fieldErrs)) {
    throw new SubmissionError(dot.object(fieldErrs));
  }
};

export const setAlertErrs = async (alerts) => {
  if (!isEmpty(alerts)) {
    var alert = "";
    alerts.map((a) => (alert = alert + a + parse("\n")));
    toastr.error("Error", alert);
  }
};
