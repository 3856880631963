import axios from "axios";

export const setHTTPHeaders = () => {
  axios.defaults.headers.common["x-auth-token"] = localStorage.token;
  axios.defaults.headers.common["x-session-id"] = localStorage.session;
};

export const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common["x-auth-token"] = token;
  } else {
    delete axios.defaults.headers.common["x-auth-token"];
  }
};
