import {
  RECIPES_CATEGORIES_LOADED,
  RECIPES_CATEGORIES_LOAD_ERROR,
  RECIPES_CATEGORY_LOADED,
  RECIPES_CATEGORY_LOAD_ERROR,
  RECIPES_CATEGORY_UNLOAD,
  RECIPES_LOADED,
  RECIPES_LOAD_ERROR,
  RECIPE_LOADED,
  RECIPE_LOAD_ERROR,
  RECIPE_UNLOAD,
} from "../actions/types";

const initialState = {
  loading: {
    categories: true,
    category: true,
    recipes: true,
    recipe: true,
  },
  categories: [],
  category: null,
  recipes: [],
  recipe: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case RECIPES_CATEGORIES_LOADED:
      return {
        ...state,
        categories: payload,
        loading: {
          ...state.loading,
          categories: false,
        },
      };
    case RECIPES_CATEGORIES_LOAD_ERROR:
      return {
        ...state,
        categories: [],
        loading: {
          ...state.loading,
          categories: false,
        },
      };
    case RECIPES_CATEGORY_LOADED:
      return {
        ...state,
        category: payload,
        loading: {
          ...state.loading,
          category: false,
        },
      };
    case RECIPES_CATEGORY_LOAD_ERROR:
      return {
        ...state,
        category: null,
        loading: {
          ...state.loading,
          category: false,
        },
      };
    case RECIPES_CATEGORY_UNLOAD:
      return {
        ...state,
        category: null,
        loading: {
          ...state.loading,
          category: true,
        },
      };

    case RECIPES_LOADED:
      return {
        ...state,
        recipes: payload,
        loading: {
          ...state.loading,
          recipes: false,
        },
      };
    case RECIPES_LOAD_ERROR:
      return {
        ...state,
        recipes: [],
        loading: {
          ...state.loading,
          recipes: false,
        },
      };
    case RECIPE_LOADED:
      return {
        ...state,
        recipe: payload,
        loading: {
          ...state.loading,
          recipe: false,
        },
      };

    case RECIPE_LOAD_ERROR:
      return {
        ...state,
        recipe: null,
        loading: {
          ...state.loading,
          recipe: false,
        },
      };
    case RECIPE_UNLOAD:
      return {
        ...state,
        recipe: null,
        loading: {
          ...state.loading,
          recipe: true,
        },
      };
    default:
      return state;
  }
}
