import axios from "axios";

import {
  REGISTER,
  LOGOUT,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN,
  USER_UPDATE_EMAIL,
  USER_UPDATE_PROFILE,
} from "./types";

import { setAuthToken } from "../utils/setHTTPHeaders";

// Load User
export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);

    try {
      const res = await axios.get("/api/v1/auth/user");
      dispatch({
        type: USER_LOADED,
        payload: { user: res.data, token: localStorage.token },
      });
    } catch (err) {
      dispatch({
        type: AUTH_ERROR,
      });
    }
  } else {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Register User
export const register = (register) => (dispatch) => {
  setAuthToken(register.token);

  dispatch({
    type: REGISTER,
    payload: register,
  });
};

// Login User
export const login = (login) => (dispatch) => {
  setAuthToken(login.token);

  dispatch({
    type: LOGIN,
    payload: login,
  });
};

// Logout / Clear Profile
export const logout = () => (dispatch) => {
  dispatch({ type: LOGOUT });
};

// Update user email
export const updateEmail = (payload) => (dispatch) => {
  dispatch({
    type: USER_UPDATE_EMAIL,
    payload,
  });
};

// Update User General Info
export const updateProfile = (data) => (dispatch) => {
  dispatch({
    type: USER_UPDATE_PROFILE,
    payload: data,
  });
};
