/***
 * AUTH
 */
export const REGISTER = "REGISTER";
export const LOGOUT = "LOGOUT";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN = "LOGIN";

/***
 * USER
 */
export const USER_UPDATE_EMAIL = "USER_UPDATE_EMAIL";
export const USER_UPDATE_PROFILE = "USER_UPDATE_PROFILE";

/***
 * SHOP
 */
// Categories
export const CATEGORIES_LOADED = "CATEGORIES_LOADED";
export const CATEGORIES_LOAD_ERROR = "CATEGORIES_LOAD_ERROR";
export const CATEGORY_LOADED = "CATEGORY_LOADED";
export const CATEGORY_LOAD_ERROR = "CATEGORY_LOAD_ERROR";
export const CATEGORY_UNLOAD = "CATEGORY_UNLOAD";

//Products
export const PRODUCTS_LOADED = "PRODUCTS_LOADED";
export const PRODUCTS_LOAD_ERROR = "PRODUCTS_LOAD_ERROR";
export const PRODUCT_LOADED = "PRODUCT_LOADED";
export const PRODUCT_LOAD_ERROR = "PRODUCT_LOAD_ERROR";
export const PRODUCT_UNLOAD = "PRODUCT_UNLOAD";

/***
 * CART
 */
export const ADD_TO_CART = "ADD_TO_CART";
export const CART_LOADED = "CART_LOADED";
export const CART_LOAD_ERROR = "CART_LOAD_ERROR";
export const UPDATE_CART = "UPDATE_CART";

/***
 * Recipes
 */
//Recipe Categories
export const RECIPES_CATEGORIES_LOADED = "RECIPES_CATEGORIES_LOADED";
export const RECIPES_CATEGORIES_LOAD_ERROR = "RECIPES_CATEGORIES_LOAD_ERROR";
export const RECIPES_CATEGORY_LOADED = "RECIPES_CATEGORY_LOADED";
export const RECIPES_CATEGORY_LOAD_ERROR = "RECIPES_CATEGORY_LOAD_ERROR";
export const RECIPES_CATEGORY_UNLOAD = "RECIPES_CATEGORY_UNLOAD";
//Recipes
export const RECIPES_LOADED = "RECIPES_LOADED";
export const RECIPES_LOAD_ERROR = "RECIPES_LOAD_ERROR";
export const RECIPE_LOADED = "RECIPE_LOADED";
export const RECIPE_LOAD_ERROR = "RECIPE_LOAD_ERROR";
export const RECIPE_UNLOAD = "RECIPE_UNLOAD";
