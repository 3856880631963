import React from "react";
import classnames from "classnames";

export const Text = (props) => {
  return (
    <React.Fragment>
      <input
        type={props.type}
        placeholder={props.placeholder}
        maxLength={props.maxLength}
        className={classnames("form-control", {
          "is-invalid": props.meta.error,
        })}
        disabled={props.disabled ? "disabled" : ""}
        min={props.min}
        max={props.max}
        {...props.input}
      />
      {props.info && (
        <small className="form-text text-muted">{props.info}</small>
      )}
      {props.meta.error && (
        <div className="invalid-feedback">
          <i className="fas fa-exclamation-circle" /> {props.meta.error}
        </div>
      )}
    </React.Fragment>
  );
};
export default Text;
