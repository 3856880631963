import {
  REGISTER,
  LOGOUT,
  USER_LOADED,
  LOGIN,
  AUTH_ERROR,
  USER_UPDATE_EMAIL,
  USER_UPDATE_PROFILE,
} from "../actions/types";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: null,
  user: null,
  loading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case USER_LOADED:
    case REGISTER:
    case LOGIN:
      localStorage.setItem("token", payload.token);
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false,
      };
    case AUTH_ERROR:
    case LOGOUT:
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        user: null,
        loading: false,
      };
    case USER_UPDATE_EMAIL:
      return {
        ...state,
        user: {
          ...state.user,
          email: payload,
        },
      };
    case USER_UPDATE_PROFILE:
      return {
        ...state,
        user: {
          ...state.user,
          name_first: payload.name_first,
          name_last: payload.name_last,
        },
      };

    default:
      return state;
  }
}
